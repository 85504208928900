import MeatballsIcon from "images/meatballs_menu_gray.svg";

const lang = {
  INTEGRATION_MARKETPLACE_SMARTBOX_TITLE: `Check out our Sample Marketplace!`,
  INTEGRATION_MARKETPLACE_SMARTBOX_DESCRIPTION: `We have created a sample Marketplace deployment for you using Doneday (Task Manager App) for you to explore.`,
  INTEGRATION_MARKETPLACE_SMARTBOX_CTA_LABEL: `Preview Sample Deployment`,
  SDK_SMARTBOX_TITLE: `Check out our Sample SDK Deployment!`,
  SDK_SMARTBOX_DESCRIPTION: `We have created a sample SDK deployment for you using Doneday (Task Manager App) for you to explore.`,
  SDK_SMARTBOX_CTA_LABEL: `Preview Sample SDK Deployment`,
  APP_CONNECTOR_SMARTBOX_TITLE: `Check out our Sample Connector!`,
  HOW_TO_GET_STARTED: `How to Get Started`,
  START_WALKTHROUGH: `Start Walkthrough`,
  HOW_TO_CREATE_A_MARKETPLACE: `How to create a Marketplace`,
  SDK_PREVIEW_SMARTBOX_TITLE: `Check out our Sample SDK Deployment!`,
  SDK_PREVIEW_SMARTBOX_DESCRIPTION: `Play around with this deployment and create Flows. When you are ready, move on to creating your own SDK embed.`,
  WATCH_TUTORIAL: `Watch Tutorial`,
  // SEARCH_CONNECTOR_PLACEHOLDER: `Search from over 100+ connectors`,
  SEARCH_CONNECTOR_PLACEHOLDER: `Search for a connector from 100+ available options`,
  REQUEST_A_CONNECTOR_PLACEHOLDER: `Can’t find the Connector you’re looking for?`,
  CANT_FIND_PLACEHOLDER: `Can't find what you're looking for?`,
  TELL_US_YOUR_NEEDS: `Tell us your needs`,
  TELL_US_MORE: `Tell us more`,
  TELL_US_ABOUT_YOU: `Tell us a bit about yourself!`,
  TELL_US_ABOUT_YOU_OLD: `Tell us about you`,
  ENTER_CONNECTOR_PLACEHOLDER: `E.g. gmail, google drive`,
  SUGGEST_FLOW_PLACEHOLDER: `E.g. New contacts in mailchimp, creates new contacts in your app`,
  SUGGEST_FLOW_PLACEHOLDER_LABEL: `What flows are you looking to offer your users?`,
  SELECT_CONNECTOR_PLACEHOLDER: `What apps do you want to setup flows with?`,
  CANCEL: `Cancel`,
  CANCEL_MEETING: `Cancel Meeting`,
  RESCHEDULE_MEETING: `Reschedule Meeting`,
  CONTINUE: `Continue`,
  SELECT_A_ROLE: `Select a role`,
  SELECT_ROLE: `Select Role`,
  PLATFORM_TYPE: `Platform Type`,
  CATEGORY: `Category`,
  COMPANY_SIZE: `Company Size`,
  COMAPNY_SIZE_PLACEHOLDER: `Select number of employees`,
  COMPANY_LINK: `Company Website`,
  COMPANY_LINK_OLD: `Company Link`,
  HAVE_A_PROMO_CODE: `Have a Promocode?`,
  ENTER_PROMO_CODE: `Enter Promocode`,
  THANKYOU_FOR_SIGNUP: `Thanks for signing up!`,
  THANKYOU_FOR_SIGNUP_OLD: `Thank you for signing up`,
  DO_THIS_LATER: `Do This Later`,
  WANT_TO_SCHEDULE_CALL: `Want to schedule a call?`,
  // WANT_TO_SCHEDULE_CALL_DESCRIPTION: `Schedule a free personalized onboarding call and get your flows up and running.`,
  WANT_TO_SCHEDULE_CALL_DESCRIPTION: `Schedule a free onboarding call to setup your connector and get your flows up and running.`,
  SCHEDULE_CALL: `Schedule Call`,
  NEED_HELP: `Need help?`,
  GET_STARTED: `Get started`,
  WEB_CONFRENCE_DETAILS_TO_FOLLOW: `Web conferencing details to follow`,
  CALL_SCHEDULED_TITLE: `You have an onboarding call scheduled with an integration specialist`,
  EXPLORE_CREATE_OWN_FLOW_SUBTITLE: `You can now explore sample flows and create flows between other apps.`,
  EXPLORE_CREATE_OWN_FLOW_SUBTITLE_OLD: `You can now explore sample flows and create your own.`,
  SCHEDULE_CALL_TITLE: `Great! Schedule your onboarding call`,
  ERROR_VALID_URL: `Please enter a valid URL`,
  ERROR_MISSING_REQUIRED_FIELD: `Required field is missing`,
  IM_READY: `I'm ready!`,
  CONNECTOR_IS_BUILDING_COPY: `Your connector will be unlocked once it's been setup by Integry.`,
  RESCHEDULE_CALL: `Reschedule Call`,
  CANCEL_CALL: `Cancel Call`,
  SEARCH_INTEGRATION_PLACEHOLDER: `Search by Integration ID, User ID or User Auth`,
  SEARCH_RUN_PLACEHOLDER: `Search by Run ID or Trigger App`,
  CALENDLY_SUB_CAPTION: `We build your app’s connector so you can get your first flow up and running!`,
  USER_ID_TOOLTIP: `Unique ID assigned to an enduser by your app. Eg: GUID.`,
  USER_AUTH_TOOLTIP: `ID used by an enduser to login to your app. Eg. username, email, etc.`,
  LAST_RUN_STATUS_TOOLTIP_OLD: `OK -> Successful run<br />
  Aborted -> Unsuccessful run<br />
  Running... -> A run is being executed<br />
  No runs as yet -> Integration hasn’t been triggered`,
  LAST_RUN_STATUS_TOOLTIP: `Completed -> Successful run (Billed)<br />
  Filtered -> Trigger data did not meet filter criteria (Not Billed)<br />
  Aborted -> Unsuccessful run<br />
  Running... -> A run is being executed<br />
  No runs as yet -> Integration hasn’t been triggered`,
  INTEGRATION_STATUS_TOOLTIP: `Initializing... -> Launching the integration<br />
  Failed to initialize -> Could not launch<br />
  Enabled -> Ready to run<br />
  Disabled -> Stopped by a user or team member<br />
  Blocked -> Stopped due to errors<br />
  Deleted -> Cannot be re-enabled`,
  NETWORK_CODE_TOOLTIP: `HTTP response status code for the last executed step in the run.`,
  INTEGRATIONS_NOT_FOUND_COPY_RUNS: `We can't find any run. Try adjusting your search or filter.`,
  INTEGRATIONS_NOT_FOUND_COPY_INTEGRATIONS: `We can't find any integrations. Try adjusting your search or filter.`,
  INTEGRATIONS_NOT_FOUND_COPY_STEPS: `We can't find any steps. Try adjusting your search or filter.`,
  INTEGRATIONS_NOT_FOUND_COPY_POLLS: `We can't find any polls. Try adjusting your search or filter.`,
  DISABLED_RETRY_RUN_COPY: `Cannot retry the run because the integration is disabled or the run payload was deleted after 30 days.`,
  DISABLED_RESUME_RUN_COPY: `Cannot resume the run because the integration is not enabled.`,
  ENABLED_RESUME_RUN_COPY: `Resume from the last failed step.`,
  APPS_NOT_FOUND_IN_FLOWS: `No apps were used inside this marketplace.`,
  INTEGRATION_HAS_NOT_RUN: `No runs to show because the integration hasn't run.`,
  INTEGRATION_HAS_NOT_RUN_1: `This integration hasn't run. To see how it was setup by the end-user`,
  PREVIEW_SETUP_HEADER_TITLE: `Preview Setup`,
  PREVIEW_SETUP_HEADER_SUBTITLE: `This is how the end-user has set up the integration`,
  VERSION: `Version`,
  NO_OLDER_VERSION_AVAILABLE: `No older versions`,
  APPS_REQUEST_PAGE_DESCRIPTION: `Collect and track app requests from your users`,
  HIDE_TAG_TOOLTIP: (
    <>
      <span>
        This app is not visible to users in your marketplace if they haven’t
        already connected it. You can make it visible for all users from the{" "}
      </span>
      <img src={MeatballsIcon} alt="meatball_ico_tag" />
      <span> menu.</span>
    </>
  ),
  ARCHIVE_CONFIRMATION_COPY: `All integrations currently enabled by your users will automatically be disabled. You will still be able to access their past runs.`,
  ARCHIVE_COMPLETION_TOAST: `is archived from your marketplace.`,
  RESTORE_MENUITEM_TOOLTIP: `The app will be added back to your marketplace but it will be hidden from new users by default.`,
  HIDE_MENUITEM_TOOLTIP: `This app will be hidden from users in your marketplace if they haven’t already connected it. You can make it visible again later.`,
  DRAFT_MENUITEM_TOOLTIP: `Hide app from users who have not connected to it. You can make it visible again later.`,
  SHOW_MENUITEM_TOOLTIP: `This app will become visible to all users in your marketplace.`,
  PUBLISH_MENUITEM_TOOLTIP: `Make app visible for all users in your marketplace.`,
  VISIBLE_TAG_TOOLTIP: (
    <>
      <span>
        This app is visible to all users in your marketplace. You can hide it
        from the{" "}
      </span>
      <img src={MeatballsIcon} alt="meatball_ico_tag" />
      <span> menu.</span>
    </>
  ),
  APP_ALREADY_ADDED: `This app is already in your marketplace`,
};
export default lang;
