const microservicesBaseUrl = "https://app.integry.io/";
const apiBaseURL = "https://app.integry.io";

// Setting Url
const setting = "/settings/";
const developerSetting = "/settings/developers/";
const userSetting = "/settings/users/";
const errorNotifications = "/settings/error-notifications/";
const embedSdkSettings = "/settings/embed/";
const appRequestSettings = "/settings/app-requests/";

// deployments URL
const deploymentsUrlV2 = "/deployments/v2";
const deploymentsUrlV3 = "/deployments/v3";
const latestDeploymentUrl = deploymentsUrlV3;

const deploymentCreateUrl = `${latestDeploymentUrl}/directory/create/basic-info`;

// Template Url
const templateUrlFlagged = "/templates/v5";
const templateUrlDefault = "/templates/v4";
const templateUrlV5 = "/templates/v5";
const templateUrlV6 = "/templates/v6";
const flowBuilder = "/flow";
const flowEdit = "/flow/:id/edit";
const flowListing = "/flows";
const templateVersionFlag = "unified-template-testing";
const latestTemplateUrl = templateUrlV6;

const users = "/users/";
const integrations = "/integrations/";
const integrationRuns = "/integrations/:integrationId/runs/";
const integrationPolls = "/integrations/:integrationId/polls/";
const runTasksV0 = "/integrations/:integrationId/runs/:runId/tasks/";
const runTasks = "/integrations/:integrationId/runs/:runId/execution-log/";

const createTemplateUrl = `${latestTemplateUrl}/create/data-flow/`;
const completeTemplateWizardUrl = `${latestTemplateUrl}/wizard-completed/`;

const feedbackCannyLink = "https://feedback.integry.io/";
const teamLink = "/settings/users/";
const companyProfile = "/settings/company-profile/";
const endUsers = "/view/users/";

const marketplace = "/marketplace/";
const marketplaceV2 = "/marketplace/v2";
const actions = "/actions/";

const appSettings = "/settings/app/";
const appSettings_actions = "/settings/app/actions";
const tagsManagement = "/settings/tags-management";
const payloadsManagement = "/settings/payloads-management";
const variables = "/settings/variables";

const embedUrl = "/embed/";
const embedBeta = "/embed-beta/";
const notFound = "/not-found";

const homePage = flowListing; // Webapp will open this page by  default.

const urls = {
  templateUrlV5,
  templateUrlFlagged,
  templateUrlDefault,
  apiBaseURL,
  microservicesBaseUrl,
  templateVersionFlag,
  templateUrlV6,
  deploymentsUrlV2,
  deploymentsUrlV3,
  setting,
  developerSetting,
  userSetting,
  errorNotifications,
  latestTemplateUrl,
  createTemplateUrl,
  latestDeploymentUrl,
  deploymentCreateUrl,
  completeTemplateWizardUrl,
  feedbackCannyLink,
  teamLink,
  companyProfile,
  endUsers,
  marketplace,
  marketplaceV2,
  embedSdkSettings,
  appRequestSettings,
  appSettings,
  appSettings_actions,
  actions,
  tagsManagement,
  payloadsManagement,
  variables,
  flowBuilder,
  flowListing,
  embedUrl,
  embedBeta,
  homePage,
  notFound,
  users,
  integrations,
  integrationRuns,
  runTasks,
  runTasksV0,
  integrationPolls,
  flowEdit,
};

export default urls;

// external URLS
const helpCenter = "https://docs.integry.ai/hc/en-us";
const privacyPolicy = "https://www.integry.io/privacy-policy";

export const externalUrl = {
  helpCenter,
  privacyPolicy,
};
