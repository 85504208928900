import { createApi } from "@reduxjs/toolkit/query/react";
import { integryBaseQuery } from "utils/http";
import { convertDateToUTCString } from "utils/date-utils";

import { PollResponse } from "../interface";

export const usePollAPI = createApi({
  reducerPath: "integry-polls",
  baseQuery: integryBaseQuery(),
  tagTypes: ["integry-polls"],
  endpoints(build) {
    return {
      getPolls: build.query<
        PollResponse,
        {
          itnegration_id: string;
          page: number;
          stepStatus: string[];
          search?: string;
          start_time_range_start?: string;
          start_time_range_end?: string;
          has_runs?: string;
        }
      >({
        query: ({
          itnegration_id,
          page,
          search,
          stepStatus,
          start_time_range_start,
          start_time_range_end,
          has_runs,
        }) => ({
          url: `/api/v1/integrations/${itnegration_id}/polls/?search=${encodeURIComponent(
            search || ""
          )}&page=${page}&page_size=10&status=${stepStatus.join(
            ","
          )}&start_time_range_start=${start_time_range_start}&start_time_range_end=${start_time_range_end}&has_runs=${has_runs}`, // &start_time_range_start=${runStartTime}&start_time_range_end=${runEndTime}
          method: "GET",
        }),
        transformResponse: (response: PollResponse, meta, args) => {
          return {
            ...response,
            results: response.results.map((item) => ({
              ...item,
              start_time: convertDateToUTCString(item.start_time),
            })),
            current_page: args.page || 1,
            next_page: args.page + 1,
            has_more: response.total_pages > args.page,
          };
        },
        serializeQueryArgs: ({ endpointName, queryArgs }) => {
          const {
            stepStatus = [],
            itnegration_id = "",
            search = "",
            start_time_range_start = "",
            start_time_range_end = "",
          } = queryArgs;
          return `${endpointName}-${itnegration_id}-${stepStatus.join(",")}-${
            search || ""
          }-${start_time_range_start || ""}-${start_time_range_end || ""}`;
        },
        // Always merge incoming data to the cache entry
        merge: (currentCache, newItems, { arg }) => {
          if (arg.page === 1) {
            return newItems;
          }
          return {
            ...newItems,
            results: [...currentCache.results, ...newItems.results],
          };
        },
        // Refetch when the page arg changes
        forceRefetch({ currentArg, previousArg }) {
          return (
            currentArg !== previousArg ||
            currentArg?.stepStatus !== previousArg?.stepStatus
          );
        },
      }),
    };
  },
});

export const { useGetPollsQuery } = usePollAPI;
