import startsWith from "lodash/startsWith";
import values from "lodash/values";
import some from "lodash/some";
import take from "lodash/take";
import { capitalize } from "lodash-es";
import clone from "lodash/clone";
import toPath from "lodash/toPath";
import { object, string, array, boolean } from "yup";
import { toast } from "react-toastify";
import equal from "deep-equal";
import cloneDeep from "clone-deep";
import resolveObjectPath from "lodash/get";

import { decodeJSON, encodeJSON, isNumeric } from "utils/functions";

import {
  getmachineNameFromTag,
  getObjectPathFromTag,
  getNodeByMachineName,
  removeNode,
} from "utils/tree";
import triggerIcon from "images/templates-v2/trigger-icon.svg";
import actionIconOn from "images/action-icon-on.svg";
import ifIcon from "images/templates-v3/if-icon-v3.svg";
import elseIfIcon from "images/templates-v3/elseif-icon-v3.svg";
import elseIcon from "images/templates-v3/else-icon-v3.svg";
import queryIcon from "images/query-icon.svg";
import adapterIcon from "images/adapter-icon.svg";
import filterIcon from "images/green-filter-icon.svg";
import loopIcon from "images/loop-emblem-2.svg";
import setupIcon from "images/setup-icon-v3.svg";
import scheduleIcon from "images/templates-v3/schedule-icon.svg";
import varIcon from "images/templates-v3/var-app-icon.svg";
import codeIcon from "images/code-icon-on.svg";
import makeHttpIcon from "images/make-http-icon.svg";
import incomingWebhookIcon from "images/incoming-webhook-icon.svg";
import notificationApp from "images/notification/notification-app.png";
import useTemplateBuilderAPI from "legacy-features/templates/v6/services/template-builder-apis";
import {
  getCodeStepTags,
  getConditionStepTags,
} from "features/templates/utils/steps-tags";
import { getObjectPropertiesRecursive } from "./functions";
import { getNode } from "./tree";
import config from "./config";
import { ActivityType } from "enums/activity-types";

const supportedStepMetaTags = [
  "activity_id",
  "http_error_code",
  "http_error_message",
  "app_name",
  "app_icon_url",
  "webhook_receive_url",
];
const triggerMetadataProperties = [
  "id",
  "name",
  "app_id",
  "app_name",
  "object_name",
  "instance_id",
  "object_action",
];

export const getHeadingForActivityType = (activityType, activityId) => {
  let heading;
  switch (activityType) {
    case "ACTION":
      heading = "Action";
      break;
    case "LOOP":
      heading = "Loop";
      break;
    case "QUERY":
      heading = "Query";
      break;
    case "ADAPTER":
      heading = "Adapter";
      break;
    case "SETUP":
      heading = "Setup";
      break;
    case "FILTER":
      heading = "Setup Filter";
      break;
    case "TRIGGER":
      heading = "Trigger";
      break;
    case "CONDITION_IF":
      heading = "If Condition";
      break;
    case "CONDITION_ELSEIF":
      heading = "Else If";
      break;
    case "CONDITION_ELSE":
      heading = "Else";
      break;
    case "SCHEDULE":
      heading = "Schedule";
      break;
    case "VARIABLE":
      heading = "Add variables";
      break;

    case "CODE":
      heading = config.code.label;
      break;
    case "MAKE_HTTP_CALL":
      heading = templateStep?.authorization_type?.app?.name || "HTTP Call";
      break;

    default:
      heading = "Select a Step";
  }
  if (activityId === "http_client") heading = "Custom Action";

  return heading;
};

export const shouldDisplayVisibilityToggle = (
  activityType,
  activityTitle = "",
  isLiteEditor = false
) => {
  switch (activityType) {
    case "LOOP":
    case "FILTER":
    case "SETUP":
    case "CONDITION_IF":
    case "CONDITION_ELSEIF":
    case "CONDITION_ELSE":
    case "SCHEDULE":
    case "VARIABLE":
    case "CODE":
      return false;
    default:
      if (
        activityTitle.toLowerCase() === "storage" ||
        activityTitle.toLowerCase() === "json" ||
        activityTitle.toLowerCase() === "memory store" ||
        activityTitle.toLowerCase() === "file append app" ||
        activityTitle.toLowerCase() === "csv app"
      ) {
        return false;
      }
      if (isLiteEditor) return false;

      return true;
  }
};

export const getLastTemplateStep = (steps) => {
  if (steps && steps.length) {
    if (steps[steps.length - 1].steps.length)
      return getLastTemplateStep(steps[steps.length - 1].steps);
    return steps[steps.length - 1];
  }
  return null;
};

export const getLastTemplateStepWithPath = (steps, path = []) => {
  if (steps.length) {
    if (steps[steps.length - 1].steps.length) {
      const stepsPath = [...path, steps.length - 1];
      return getLastTemplateStepWithPath(
        steps[steps.length - 1].steps,
        stepsPath
      );
    }
    steps[steps.length - 1].stepPath = [...path, steps.length - 1];
    return steps[steps.length - 1];
  }
  return null;
};

export const getHeadingPrefixForConditionActivityType = (activityType) => {
  let heading;
  switch (activityType) {
    case "CONDITION_IF":
      heading = "If Condition";
      break;
    case "CONDITION_ELSEIF":
      heading = "Else If";
      break;
    case "CONDITION_ELSE":
      heading = "Else";
      break;
    default:
      heading = "Condition";
  }

  return heading;
};

export const getStepTitle = (activityType, activity) => {
  let activityName = "";

  switch (activityType) {
    case "SETUP":
      activityName = "Setup App";
      break;
    case "LOOP":
      activityName = "Loop App";
      break;
    case "SCHEDULE":
      activityName = "Schedule Task";
      break;
    case "VARIABLE":
      activityName = "Set Var";
      break;
    case "CODE":
      activityName = config.code.label;
      break;
    default:
      activityName = activity ? activity.name : capitalize(activityType);
      break;
  }

  return activityName;
};
export const getSubHeadingForActivityType = (activityType, activityId) => {
  let heading;
  switch (activityType) {
    case "ACTION":
      heading = (
        <>
          An action is an activity performed by Integry in a specific app{" "}
          <a
            href="https://support.integry.io/hc/en-us/articles/360021913574-About-Actions"
            target="_blank"
            rel="noopener noreferrer"
          >
            Learn more.
          </a>
        </>
      );
      break;
    case "QUERY":
      heading = (
        <>
          A query fetches a chunk of data from a specific app to Integry, for
          further process.{" "}
          <a
            href="https://support.integry.io/hc/en-us/articles/360036054454-Using-and-Testing-Queries-in-Templates"
            target="_blank"
            rel="noopener noreferrer"
          >
            Learn more.
          </a>
        </>
      );
      break;
    case "LOOP":
      heading = (
        <>
          A Loop iterates over all the objects of an incoming array of data.
          This way, you can perform a specific operation on all the objects of
          an array one by one.{" "}
          <a
            href="https://support.integry.io/hc/en-us/articles/360036042554-Using-Loops-in-Templates"
            target="_blank"
            rel="noopener noreferrer"
          >
            Learn more.
          </a>
        </>
      );
      break;
    case "ADAPTER":
      heading = (
        <>
          An adapter maps the input data based upon what the user selects in the
          mapping step of the integration.{" "}
          <a
            href="https://support.integry.io/hc/en-us/articles/360038026533-Using-Adapters-in-Templates"
            target="_blank"
            rel="noopener noreferrer"
          >
            Learn more.
          </a>
        </>
      );
      break;
    case "SETUP":
      heading = (
        <>
          The Setup step runs all its child steps once when the user sets up the
          integration. It can only be used on the root level of a flow.{" "}
          <a
            href="https://support.integry.io/hc/en-us/articles/360036054454-Using-and-Testing-Queries-in-Templates"
            target="_blank"
            rel="noopener noreferrer"
          >
            Learn more.
          </a>
        </>
      );
      break;
    case "FILTER":
      heading = (
        <>
          Filters are used to apply basic transformations on an input array of
          JSON format.{" "}
          <a
            href="https://support.integry.io/hc/en-us/articles/360035880193-Data-Transformation-in-Templates-using-the-Filter-step"
            target="_blank"
            rel="noopener noreferrer"
          >
            Learn more.
          </a>
        </>
      );
      break;
    case "TRIGGER":
      if (activityId !== config.incomingWebhook.activityId)
        heading = (
          <>
            A trigger informs Integry of an event taking place in a specific
            app.{" "}
            <a
              href="https://support.integry.io/hc/en-us/articles/360021913494-About-Triggers"
              target="_blank"
              rel="noopener noreferrer"
            >
              Learn more.
            </a>
          </>
        );
      break;
    case "SCHEDULE":
      heading = "";
      break;
    case "VARIABLE":
      heading = "Create variables that can be used in the flow.";
      break;
    case "NOTIFICATION":
      heading = "Send notifications to your users";
      break;
    case config.external_action.activityType:
      heading = "Setup the external Action.";
      break;
    case "CODE":
      heading = (
        <>
          Write Python 3.12 code.{" "}
          {/* <a
            href="https://support.integry.io/hc/en-us/articles/9561161702041-Code-App"
            target="_blank"
            rel="noopener noreferrer"
          >
            Learn more.
          </a> */}
        </>
      );
      break;
    case "CONDITION_IF":
      heading = (
        <>
          Add conditional logic to your flow. If your condition is true, the
          child steps would run.{" "}
          <a
            href="https://support.integry.io/hc/en-us/articles/360021913214-Adding-Conditions-in-a-Template"
            target="_blank"
            rel="noopener noreferrer"
          >
            Learn more.
          </a>
        </>
      );
      break;
    case "CONDITION_ELSEIF":
      heading = (
        <>
          <a
            href="https://support.integry.io/hc/en-us/articles/360021913214-Adding-Conditions-in-a-Template"
            target="_blank"
            rel="noopener noreferrer"
          >
            Learn more{" "}
          </a>
          about conditional logics in flows.
        </>
      );
      break;
    case "CONDITION_ELSE":
      heading = (
        <>
          <a
            href="https://support.integry.io/hc/en-us/articles/360021913214-Adding-Conditions-in-a-Template"
            target="_blank"
            rel="noopener noreferrer"
          >
            Learn more{" "}
          </a>
          about conditional logics in flows.
        </>
      );
      break;
    case config.doWhileLoop.activity.type:
      heading = (
        <>
          A Do-While loop run its child steps at least once and then checks the
          condition. If the condition is true, it runs the child steps again.
        </>
      );
      break;
    default:
      heading = "";
  }

  return heading;
};

export const getDefaultIconForActivityType = (activityType, appId = 0) => {
  let defaultIcon;
  switch (activityType) {
    case "ACTION":
      defaultIcon = actionIconOn;
      break;
    case "QUERY":
      defaultIcon = queryIcon;
      break;
    case "LOOP":
      defaultIcon = loopIcon;
      break;
    case "FILTER":
      defaultIcon = filterIcon;
      break;
    case "ADAPTER":
      defaultIcon = adapterIcon;
      break;
    case "TRIGGER":
      if (appId === config.incomingWebhook.appId)
        defaultIcon = incomingWebhookIcon;
      else defaultIcon = triggerIcon;
      break;
    case "CONDITION_IF":
      defaultIcon = ifIcon;
      break;
    case "CONDITION_ELSEIF":
      defaultIcon = elseIfIcon;
      break;
    case "CONDITION_ELSE":
      defaultIcon = elseIcon;
      break;
    case "SETUP":
      defaultIcon = setupIcon;
      break;
    case "SCHEDULE":
      defaultIcon = scheduleIcon;
      break;
    case "VARIABLE":
      defaultIcon = varIcon;
      break;
    case "CODE":
      defaultIcon = codeIcon;
      break;
    case "MAKE_HTTP_CALL":
      defaultIcon = makeHttpIcon;
      break;
    case "NOTIFICATION":
      defaultIcon = notificationApp;
      break;
    case config.external_action.activityType:
      defaultIcon = config.external_action.appIcon;
      break;
    case config.doWhileLoop.activity.type:
    case config.doWhileLoop.dummyStepType:
      defaultIcon = config.doWhileLoop.icon;
      break;

    default:
      defaultIcon = actionIconOn;
  }

  return defaultIcon;
};

export const getStepIconFromTemplateStep = (step) => {
  const { activity, activity_type, is_form_step } = step || {};
  const { app } = activity || {};
  const { icon_url } = app || {};
  const defaultIcon = getDefaultIconForActivityType(activity_type, step.app_id);
  if (is_form_step) {
    return step?.app?.icon_url || defaultIcon;
  }
  switch (activity_type) {
    case "ACTION":
    case "QUERY":
    case "ADAPTER":
    case "TRIGGER":
      return icon_url || defaultIcon;
    default:
      return defaultIcon;
  }
};

export const getActivityTitleFromTemplateStep = (step) => {
  const { activity, activity_type } = step || {};
  switch (activity_type) {
    case "ACTION":
    case "QUERY":
    case "ADAPTER":
    case "TRIGGER":
      return activity ? activity.name : "";
    case "SETUP":
      return "Setup";
    case "LOOP":
      return "Loop";
    case "SCHEDULE":
      return "Schedule";
    case "VARIABLE":
      return "Variable";
    case "CODE":
      return "Code";
    case "CONDITION_IF":
      return "If Condition";
    case "CONDITION_ELSEIF":
      return "Else If";
    case "CONDITION_ELSE":
      return "Else";
    default:
      return "";
  }
};

export const hasApp = (templateStep) =>
  !!(templateStep.app_id && templateStep.app_id.toString().length > 0);

export const hasActivity = (templateStep) =>
  !!(templateStep.activity_id && String(templateStep.activity_id).length > 0);

export const isCollapsible = (templateStep) =>
  startsWith(templateStep.activity_type, "CONDITION") &&
  templateStep.activity_type !== "CONDITION_ELSE"
    ? templateStep.condition_obj &&
      templateStep.condition_obj.conditions &&
      templateStep.condition_obj.conditions.length > 0 &&
      !some(
        // There is atleast one error
        values(
          validateSimpleCondition(templateStep.condition_obj.conditions[0])
        ),
        (x) => x !== null
      )
    : true;

export const elseIfVisible = (steps) =>
  steps.length > 0 &&
  (steps[steps.length - 1].activity_type === "CONDITION_IF" ||
    steps[steps.length - 1].activity_type === "CONDITION_ELSEIF");

export const elseVisible = (steps) =>
  steps.length > 0 &&
  (steps[steps.length - 1].activity_type === "CONDITION_IF" ||
    steps[steps.length - 1].activity_type === "CONDITION_ELSEIF");

export const getAvailableTagsFromActivity = (activity, templateStep) => {
  let outTags = [];
  const sdkTags = [];
  let inTags = [];
  if (activity?.activity_output) {
    try {
      const tagsObject = JSON.parse(activity.activity_output);
      outTags = getObjectPropertiesRecursive(tagsObject, "");
    } catch (e) {
      toast.error(
        `
        Invalid JSON provided in ${activity.app.name} app
        with activity ID ${activity.id} 
        `
      );
      outTags = [];
    }
  }

  if (templateStep && templateStep.fields) {
    getTemplateTagsForStepRecursive(templateStep.fields, sdkTags);
  }
  if (activity?.activity_fields)
    inTags = activity.activity_fields
      .filter((f) => f.type !== "HIDDEN")
      .map((field) => field.machine_name);

  if (templateStep.is_form_step) {
    inTags = templateStep.fields.map((field) => field.machine_name);
  }
  return {
    activityName: `${activity?.app && activity?.app?.name}: ${activity?.name}`,
    outTags,
    inTags,
    sdkTags,
  };
};

export const getAvailableTagsForVariableStep = (templateStep) => {
  let varTags = [];
  const { variables } = templateStep;
  if (variables && variables.length > 0) {
    varTags = variables.map((variable) => variable.name);
  }

  return {
    activityName: `Variable Step`,
    varTags,
  };
};

export const getAvailableTagsForTemplateStep = (path, availableTags) => {
  const triggerMetadataTags = {
    activityName: "Trigger Metadata",
    tags: triggerMetadataProperties.map((propertyName) => ({
      label: propertyName,
      value: `{trigger.${propertyName}}`,
    })),
  };

  return [
    triggerMetadataTags,
    ...getAvailableTagsForTemplateStepRecursive(path, availableTags),
  ];
};

export const getAvailableTagsForTemplateMeta = (availableTags) => {
  const path = [availableTags.steps.length];
  return getAvailableTagsForTemplateStep(path, availableTags);
};

const getAvailableTagsForTemplateStepRecursive = (
  path,
  availableTags,
  inPrefix = "",
  outPrefix = ""
) => {
  if (path.length === 0) {
    return [];
  }
  if (path.length === 1) {
    return take(availableTags.steps, path[0] + 1).map(
      (stepAvailableTags, stepIndex) => {
        const { steps, ...selfTagsObject } = stepAvailableTags;

        const inTags = selfTagsObject.inTags
          ? selfTagsObject.inTags.map((tag) => ({
              label: `IN: ${tag}`,
              value: `{${inPrefix}${
                inPrefix.length > 0 ? "." : ""
              }steps.${stepIndex}.in.${tag}}`,
            }))
          : [];
        const outTags = selfTagsObject.outTags
          ? selfTagsObject.outTags.map((tag) => ({
              label: `OUT: ${tag}`,
              value: `{${outPrefix}${
                outPrefix.length > 0 ? "." : ""
              }steps.${stepIndex}.out.${tag}}`,
            }))
          : [];

        const sdkTags = selfTagsObject.sdkTags
          ? selfTagsObject.sdkTags.map((tag) => ({
              label: `SDK: ${tag}`,
              value: `{${outPrefix}${
                outPrefix.length > 0 ? "." : ""
              }steps.${stepIndex}.sdk.${tag}}`,
            }))
          : [];

        // Add META tags only if there is atleast one IN or OUT tag.
        const metaTags =
          inTags.length > 0 || outTags.length > 0
            ? supportedStepMetaTags.map((tag) => ({
                label: `META: ${tag}`,
                value: `{${outPrefix}${
                  outPrefix.length > 0 ? "." : ""
                }steps.${stepIndex}.meta.${tag}}`,
              }))
            : [];
        const varTags = selfTagsObject.varTags
          ? selfTagsObject.varTags.map((tag) => ({
              label: `VAR: ${tag}`,
              value: `{${tag}}`,
            }))
          : [];
        return {
          activityName: selfTagsObject.activityName,
          tags: [...metaTags, ...inTags, ...outTags, ...sdkTags, ...varTags],
        };
      }
    );
  }
  const [current, ...restPath] = path;

  return [
    ...getAvailableTagsForTemplateStepRecursive(
      [current],
      availableTags,
      inPrefix,
      outPrefix
    ),
    ...getAvailableTagsForTemplateStepRecursive(
      restPath,
      availableTags.steps[current],
      `${inPrefix}${inPrefix.length > 0 ? "." : ""}steps.${current}`,
      `${outPrefix}${outPrefix.length > 0 ? "." : ""}steps.${current}`
    ),
  ];
};

export const isTag = (str) => {
  if (str && str[0] === "{" && str[str.length - 1] === "}") {
    return true;
  }
  return false;
};

export const stringifyCondition = (
  condition_obj,
  ignoreTag = false,
  includeQuotes = true
) => {
  const conditionText =
    condition_obj.operand_1 && condition_obj.operator
      ? `${includeQuotes ? '"' : ""}${
          ignoreTag
            ? !isTag(condition_obj.operand_1)
              ? condition_obj.operand_1
              : ""
            : condition_obj.operand_1
        }${includeQuotes ? '"' : ""}
    ${config.conditionalOptions[condition_obj.operator]} ${
          includeQuotes ? '"' : ""
        }${
          ignoreTag
            ? !isTag(condition_obj.operand_2)
              ? condition_obj.operand_2 || ""
              : ""
            : condition_obj.operand_2 || ""
        }${includeQuotes ? '"' : ""}`
      : "";
  return conditionText;
};

export const stringifyMultipleConditions = (condition_obj, activity_type) => {
  // Map each condition to a string and then join them with the relation
  const conditionsString = condition_obj.conditions
    .map((condition) => {
      const hasOperand2 =
        condition.operator !== "IS_EMPTY" &&
        condition.operator !== "IS_TRUE" &&
        condition.operator !== "IS_NOT_TRUE" &&
        condition.operator !== "IS_NOT_EMPTY" &&
        condition.operator !== "IS_NULL" &&
        condition.operator !== "IS_NOT_NULL" &&
        condition.operator !== "EXISTS" &&
        condition.operator !== "DOES_NOT_EXIST";

      if (condition.operand_1) {
        const operand_1 = isNumeric(condition.operand_1)
          ? condition.operand_1
          : `"${condition.operand_1}"`;
        const operand_2 = isNumeric(condition.operand_2)
          ? condition.operand_2
          : `${condition.operand_2 ? `"${condition.operand_2}"` : ""}`;
        return `${operand_1 || ``} ${
          config.conditionalOptions[condition.operator]
        } ${hasOperand2 ? operand_2 : ``}`;
      }
      return "";
    })
    .filter((title) => !!title)
    .join(` ${condition_obj.relation} `);
  if (!conditionsString)
    return getHeadingPrefixForConditionActivityType(activity_type);
  return conditionsString;
};

export const checkIfLiteTemplate = () => {
  const { pathname } = window.location;
  const liteTemplateCheck = pathname
    .substring(pathname.length - 5, pathname.length)
    .includes("lite");
  return liteTemplateCheck;
};

export const isIntegrationBuilder = () => {
  const { pathname } = window.location;
  return pathname.includes("/flow/");
};

export const validateSimpleCondition = (values) => {
  const validationSchema = object().shape({
    operand_1: string().required("Value is required").nullable(),
    operator: string().required("Operator is required").nullable(),
    operand_2: string()
      // .required('Operand is required')
      .nullable(),
  });

  const baseErrors = {
    operand_1: null,
    operator: null,
    operand_2: null,
  };

  const errors = {};
  try {
    validationSchema.validateSync(values, { abortEarly: false });
  } catch (e) {
    for (const error of e.inner) {
      errors[error.params.path] = error.message;
    }
  }

  const allErrors = Object.assign(baseErrors, errors);
  return allErrors;
};

export const validateTemplateStep = (values) => {
  const validationSchema = object().shape({
    app_id: string().required("Please select an app.").nullable(),
    activity_id: string().required("Please select an activity.").nullable(),
    activity_type: string(),
    fields: array().of(
      object().shape({
        activity_field_is_required: boolean(),
        is_visible: boolean(),
        default_value: string()
          .when(["activity_field_is_required", "is_visible", "type"], {
            is: (activity_field_is_required, is_visible, type) =>
              activity_field_is_required &&
              !is_visible &&
              type !== "ACTIVITY_FIELD",
            then: string().required(
              "If you hide a required field, you must provide a default value for it."
            ),
            otherwise: string().nullable(),
          })
          .when("activity_type", {
            is: "LOOP",
            then: string().required("This field is required.").nullable(),
          }),
      })
    ),
    variables: array()
      .when("activity_type", {
        is: "VARIABLE",
        then: array().of(
          object().shape({
            name: string()
              .required("Please enter a name.")
              .nullable()
              .notOneOf(
                ["steps", "trigger"],
                `Name can't be "steps" or "trigger"`
              ),
            value: string().required("Please enter a value.").nullable(),
          })
        ),
      })
      .nullable(),
    condition_obj: object().shape({
      conditions: array().of(
        object().shape({
          operand_1: string().required("Value is required").nullable(),
          operator: string().required("Operator is required").nullable(),
          operand_2: string().required("Value is required").nullable(),
        })
      ),
    }),
  });
  let errors = {};
  // console.log('step',values)
  try {
    validationSchema.validateSync(values, { abortEarly: true });
  } catch (e) {
    // console.log('orig',e)
    errors = yupToFormErrors(e);
  }
  // console.log('ver',errors)
  // focusOnError(errors, path);
  return errors;
};

export const isInteger = (obj) => String(Math.floor(Number(obj))) === obj;

/**
 * Deeply get a value from an object via its path.
 */
export function getIn(obj, key, def, p = 0) {
  const path = toPath(key);
  while (obj && p < path.length) {
    obj = obj[path[p++]];
  }
  return obj === undefined ? def : obj;
}

export function setIn(obj, path, value) {
  const res = clone(obj); // this keeps inheritance when obj is a class
  let resVal = res;
  let i = 0;
  const pathArray = toPath(path);

  for (; i < pathArray.length - 1; i++) {
    const currentPath = pathArray[i];
    const currentObj = getIn(obj, pathArray.slice(0, i + 1));

    if (currentObj) {
      resVal = resVal[currentPath] = clone(currentObj);
    } else {
      const nextPath = pathArray[i + 1];
      resVal = resVal[currentPath] =
        isInteger(nextPath) && Number(nextPath) >= 0 ? [] : {};
    }
  }

  // Return original object if new value is the same as current
  if ((i === 0 ? obj : resVal)[pathArray[i]] === value) {
    return obj;
  }

  if (value === undefined) {
    delete resVal[pathArray[i]];
  } else {
    resVal[pathArray[i]] = value;
  }

  // If the path array has a single element, the loop did not run.
  // Deleting on `resVal` had no effect in this scenario, so we delete on the result instead.
  if (i === 0 && value === undefined) {
    delete res[pathArray[i]];
  }

  return res;
}

export function yupToFormErrors(yupError) {
  let errors = {};
  if (yupError.inner) {
    if (yupError.inner.length === 0) {
      return setIn(errors, yupError.path, yupError.message);
    }
    for (const err of yupError.inner) {
      if (!errors[err.path]) {
        errors = setIn(errors, err.path, err.message);
      }
    }
  }
  return errors;
}

export const adjustTagsAfterAddingTemplateStep = (tree, path) => {
  const currentPath = createPathPrefixForTags(path);
  const newIndex = path[path.length - 1];

  return replaceTagsPathPrefix(tree, `{${currentPath}`, newIndex, "ADD", true);
};

export const adjustTagsAfterDeletingTemplateStep = (tree, path) => {
  const currentPath = createPathPrefixForTags(path);
  const newIndex = path[path.length - 1];
  return replaceTagsPathPrefix(
    tree,
    `{${currentPath}`,
    newIndex,
    "DELETE",
    true
  );
};

export const getAllMachineNames = (tree, isRoot = false) => {
  const response = [];
  if (isRoot) {
    tree.steps.forEach((child) => {
      const subResponse = getAllMachineNames(child);
      response.push(...subResponse);
    });
  } else {
    response.push(tree.machine_name);
    if (Array.isArray(tree.steps))
      tree.steps.forEach((child) => {
        const subResponse = getAllMachineNames(child);
        response.push(...subResponse);
      });
  }
  return response;
};

export const markStepHavingInvalidTags = (tree, isRoot = false) => {
  const machineNames = getAllMachineNames(tree);
  return markStepsRecursivly(tree, machineNames, isRoot);
};

const markStepsRecursivly = (tree, machineNames, isRoot = false) => {
  const newStep = tree;
  if (!isRoot) {
    if (newStep.app_name === "Conditions" && newStep.condition_obj) {
      const { conditions = [] } = newStep.condition_obj;
      conditions.forEach((condition) => {
        const { operand_1, operand_2 } = condition;
        const hasInvalidTag =
          hasDeletedStepTag(machineNames, operand_1) ||
          hasDeletedStepTag(machineNames, operand_2);
        newStep.has_invalid_tag = hasInvalidTag;
      });
    } else if (newStep.activity_type === "VARIABLE" && newStep.variables) {
      const hasInvalidTag = newStep.variables.some((variable) =>
        hasDeletedStepTag(machineNames, variable.value)
      );
      newStep.has_invalid_tag = hasInvalidTag;
    } else if (newStep.hasOwnProperty("fields")) {
      const { step_condition_obj = {} } = newStep;
      const { operand_1 = "", operand_2 = "" } = step_condition_obj || {};
      const hasInvalidTag =
        hasDeletedStepTag(machineNames, operand_1) ||
        hasDeletedStepTag(machineNames, operand_2) ||
        newStep.fields.some((field) =>
          hasDeletedStepTag(machineNames, field.default_value)
        );
      newStep.has_invalid_tag = hasInvalidTag;
    }
  }
  newStep.steps.map((step) => markStepsRecursivly(step, machineNames));
  return newStep;
};

const hasDeletedStepTag = (machineNames, tags) => {
  const re = /({[^{}"]*?})/g;
  let hasInvalidTag = false;
  if (tags && typeof tags === "string" && tags.length > 0) {
    tags = tags.split(re).filter(Boolean);
    const tagRegex = /{.*}/;
    tags.forEach((tag) => {
      if (tagRegex.test(tag)) {
        try {
          const tagMachineName = tag.split(".")[1];
          if (machineNames.indexOf(tagMachineName) < 0) {
            hasInvalidTag = true;
            return;
          }
        } catch (e) {
          console.log(e);
        }
      }
    });
  }
  return hasInvalidTag;
};

export const adjustTagsAfterDragNDrop = (
  tree,
  searchPath,
  type,
  isRoot = false,
  replaceIndex = 0
) => {
  const currentPath = createPathPrefixForTags(searchPath);
  const destNodeIndex = searchPath[searchPath.length - 1];

  /* currentPath =
    type === 'AdjustDestinationTags'
      ? `{${currentPath}${destNodeIndex}`
      : `{${currentPath}`;
 */

  return replaceTagsPathPrefix(
    tree,
    `{${currentPath}`,
    destNodeIndex,
    type,
    isRoot,
    replaceIndex
  );
};

const replaceTagsPathPrefix = (
  step,
  currentPath,
  prevIndex,
  type,
  isRoot = false,
  replaceIndex = 0
) => {
  const newStep = step;
  if (!isRoot) {
    const params = [currentPath, prevIndex, type, replaceIndex];
    if (newStep.app_name === "Conditions" && newStep.condition_obj) {
      const { conditions } = newStep.condition_obj;

      newStep.condition_obj.conditions = conditions.map((condition) => {
        const { operand_1, operand_2 } = condition;
        condition.operand_1 = createUpdatedTag(...params, operand_1);
        condition.operand_2 = createUpdatedTag(...params, operand_2);
        return condition;
      });
    }
    if (newStep.hasOwnProperty("fields")) {
      newStep.fields = newStep.fields.map((field) => {
        field.default_value = createUpdatedTag(...params, field.default_value);
        return field;
      });
    }
  }

  newStep.steps = newStep.steps.map((step) =>
    replaceTagsPathPrefix(
      step,
      currentPath,
      prevIndex,
      type,
      false,
      replaceIndex
    )
  );

  return newStep;
};

const createPathPrefixForTags = (path) => {
  if (path.length === 1) {
    return `steps.`;
  }
  const [idx, ...rest] = path;
  return `steps.${idx}.${createPathPrefixForTags(rest)}`;
};

const createUpdatedTag = (
  currentPath,
  prevIndex,
  type,
  replaceIndex,
  defaultValues
) => {
  const re = /({[^{}"]*?})/g;

  if (
    typeof defaultValues === "string" &&
    defaultValues &&
    defaultValues.length > 0
  ) {
    defaultValues = defaultValues.split(re).filter(Boolean);
    defaultValues = defaultValues.map((defaultValue) => {
      const pathIndex = defaultValue.indexOf(currentPath);
      if (pathIndex > -1) {
        const index =
          defaultValue.indexOf(".", currentPath.length) - currentPath.length;
        const stringIndex = defaultValue.substring(
          currentPath.length,
          currentPath.length + index
        );
        const currentIndex = parseInt(stringIndex);
        const newIndex =
          type === "DELETE" ? currentIndex - 1 : currentIndex + 1;
        const currentPathWithIndex = `${currentPath}${currentIndex.toString()}`;
        let newPathWithIndex = `${currentPath}${newIndex.toString()}`;
        if (type === "DELETE" || type === "ADD") {
          if (currentIndex > prevIndex) {
            return defaultValue.replace(currentPathWithIndex, newPathWithIndex);
          }
          if (currentIndex === prevIndex) {
            return type === "DELETE" ? "" : defaultValue;
          }
          return defaultValue;
        }
        if (type === "DragDownToTop_Source") {
          if (currentIndex >= prevIndex) {
            return "";
          }
          return defaultValue;
        }
        if (
          type === "DragDownToTopReferenceOfSource" ||
          type === "AdjustDestinationTags"
        ) {
          console.log(type, currentIndex, prevIndex);
          if (currentIndex === prevIndex) {
            console.log("replaceIndex", replaceIndex);
            newPathWithIndex = `${currentPath}${replaceIndex.toString()}`;
            return defaultValue.replace(currentPathWithIndex, newPathWithIndex);
          }
          return defaultValue;
        }
      } else return defaultValue;
      return defaultValue;
    });
    return defaultValues.join("");
  }
  return defaultValues;
};

const getTemplateTagsForStepRecursive = (
  fields,
  sdkTags,
  index = 0,
  prefix = ""
) => {
  if (fields.length === 0) {
    return "";
  }
  fields.map((field, stepIndex) => {
    if (field.type !== "ACTIVITY_FIELD" && field.is_visible) {
      sdkTags.push(`${prefix}${prefix.length > 0 ? "." : ""}sdk.${stepIndex}`);
    }
    if (field.fields && field.fields.length > 0 && field.is_visible) {
      getTemplateTagsForStepRecursive(
        field.fields,
        sdkTags,
        stepIndex,
        `${prefix}${prefix.length > 0 ? ".sdk." : ""}${stepIndex}`
      );
    }

    return "";
  });
};

export const getActivityURL = (appID, activityType, activityID) => {
  let activityTypePlural = "";

  if (appID || activityType || activityID) {
    switch (activityType) {
      case "query":
        activityTypePlural = "queries";
        break;
      case "action":
        activityTypePlural = "actions";
        break;
      case "trigger":
        activityTypePlural = "triggers";
        break;
      case "adapter":
        activityTypePlural = "adapters";
        break;
      default:
        activityTypePlural = "";
    }
    return `/wapp/apps/${appID}/edit/${activityTypePlural}/${activityID}/edit`;
  }
  return `#`;
};

export const getMachineNameForOtherActivities = (activityType) => {
  switch (activityType) {
    case "SETUP":
      return "setup";
    case "LOOP":
      return "loop";
    case "SCHEDULE":
      return "schedule";
    case "VARIABLE":
      return "variable";
    case "CODE":
      return "custom_code";
    case "CONDITION_IF":
      return "If";
    case "CONDITION_ELSEIF":
      return "Else_If";
    case "CONDITION_ELSE":
      return "Else";
    case "NOTIFICATION":
      return "notification_step";
    case config.external_action.activityType:
      return "external_action_step";
    case config.doWhileLoop.activityType:
      return "do_while_loop";
    case "MAKE_HTTP_CALL":
      return "http_call";
    case "TRIGGER":
      return "webhook";
    default:
      return activityType;
  }
};

export const isParentStep = (activityType) => {
  const conditionalStep = !!startsWith(activityType, "CONDITION");
  return (
    conditionalStep ||
    activityType === "QUERY" ||
    activityType === "SETUP" ||
    activityType === "SCHEDULE" ||
    activityType === "TRIGGER" ||
    activityType === "LOOP" ||
    activityType === "CONDITION_IF" ||
    activityType === "CONDITION_ELSE" ||
    activityType === "CONDITION_ELSEIF" ||
    activityType === ActivityType.DoWhile_Loop
  );
};
const getLatStepPath = (path) => {
  if (path.length === 1 && equal(path, [0])) {
    return path;
  }
  const copiedAarray = [...path];
  const lastIndex = copiedAarray.length - 1;
  if (copiedAarray[lastIndex] === 0) {
    copiedAarray.splice(-1, 1);
    return copiedAarray;
  }
  copiedAarray.splice(-1, 1, path[path.length - 1] - 1);
  return copiedAarray;
};

export const queryStringToObject = (queryParams) => {
  if (queryParams) {
    let params = queryParams.substring(1);
    params = `{"${params.replace(/&/g, '","').replace(/=/g, '":"')}"}`;
    try {
      params = JSON.parse(params);
    } catch (error) {
      console.log(error);
    }
    return params;
  }
  return "";
};

export const getAllowedAppsByStepLocation = (
  template,
  path,
  systemActivity,
  systemApps,
  allowMoreSetupStep
) => {
  const lastIndex = path.length - 1;
  const firstStepApps = ["SETUP", "SCHEDULE", "VARIABLE", "TRIGGER"];
  const blackListApps = [
    config.csvApp.appId,
    config.jsonApp.appId,
    config.fileApp.appId,
    config.storage.appId,
    config.memoryApp.appId,
    config.setup.appId,
  ];
  let filtredActivity = [...systemActivity];

  if (equal(path, [0])) {
    // first step being added in template
    filtredActivity = filtredActivity.filter(
      (activty) => firstStepApps.indexOf(activty.activity_type) >= 0
    );
    systemApps = systemApps.filter(
      (app) => blackListApps.indexOf(app.id) === -1
    );
  } else {
    const lastStepPath = getLatStepPath(path);
    const lastStep = getNode(template, "steps", lastStepPath);
    if (
      path[lastIndex] === 0 ||
      (lastStep.activity_type !== "CONDITION_IF" &&
        lastStep.activity_type !== "CONDITION_ELSEIF")
    ) {
      filtredActivity = filtredActivity.filter(
        (activty) =>
          activty.activity_type !== "CONDITION_ELSEIF" &&
          activty.activity_type !== "CONDITION_ELSE"
      );
    }
  }
  if (!allowMoreSetupStep || path.length > 1) {
    filtredActivity = filtredActivity.filter(
      (activty) => activty.activity_type !== "SETUP"
    );
  }
  if (path.length === 1) {
    systemApps = systemApps.filter(
      (app) => blackListApps.indexOf(app.id) === -1
    );
    filtredActivity = filtredActivity.filter(
      (activty) =>
        activty.activity_type !== "CONDITION_ELSEIF" &&
        activty.activity_type !== "CONDITION_ELSE" &&
        activty.activity_type !== "CONDITION_IF"
    );
  }

  return [...filtredActivity, ...systemApps];
};

export const showElseSteps = (template, path) => {
  const lastIndex = path.length - 1;
  if (path.length > 1 && path[lastIndex] > 0) {
    // not first step and not first child
    const lastStepPath = getLatStepPath(path);
    const lastStep = getNode(template, "steps", lastStepPath);
    return (
      lastStep.activity_type === "CONDITION_IF" ||
      lastStep.activity_type === "CONDITION_ELSEIF"
    );
  }
  return false;
};

export const hasSetupStep = (template) => {
  // search for setup step in template steps at root level only
  // if found return true
  return template.steps.some((step) => step.activity_type === "SETUP");
};

export const filterAppsByAllowedActivityType = (path, apps) => {
  /* 1. If a step is being added at a root level, show apps which has atleast one trigger 
    2. If a step is being added under setup, show apps which has atleast one queries or actions.
    3. If the step is being added under a parent step (like a trigger, adapter, loop, schedule etc), 
       show apps which has atleast one actions or queries.
    if there is no activity to show in app accouding to above, don't show that app at all in the selection. */
  if (path.length === 1) {
    // Root Level
    apps = apps.filter((app) => app.triggers_count > 0);
  } else if (path.length > 1) {
    apps = apps.filter(
      (app) =>
        app.queries_count > 0 ||
        app.actions_count > 0 ||
        app.adapters_count > 0 ||
        !!app.auto_select_activity
    );
  }
  return apps;
};

export const shouldShowActivityDropdown = (path, app, activities = []) => {
  return (
    (path.length === 1 && app.triggers_count !== 0) ||
    (path.length !== 1 &&
      (app.queries_count !== 0 ||
        app.actions_count !== 0 ||
        activities.some((activity) => activity.type === "ACTION")))
  );
};

export const hasPathInTag = (tags) => {
  const re = /({[^{}"]*?})/g;
  let hasPath = false;
  if (tags && tags.length > 0 && typeof tags === "string") {
    tags = tags.split(re).filter(Boolean);
    let i = 0;
    while (i < tags.length && !hasPath) {
      try {
        const tagMachineName = tags[i]
          .replace("{", "")
          .replace("}", "")
          .split(".")[1];
        hasPath = isNumericString(tagMachineName);
        i++;
      } catch (e) {
        console.log(e);
      }
    }
  }
  return hasPath;
};

export const isValidTag = (tags, isCustom = false) => {
  let isValid = true;
  let re = /({[^{}"]*?})/g;
  if (isCustom) {
    re = /({{[^{}"]*?}})/g;
  }
  let validTags = 0;
  if (tags && typeof tags === "string" && tags.length > 0) {
    const tagsAfterSplit = tags.split(re).filter(Boolean);
    tagsAfterSplit.forEach((tag) => {
      let currentTagValid = true;
      if (tag[0] !== "{" || tag[tag.length - 1] !== "}") {
        if (isCustom) {
          if (tag[1] !== "{" || tag[tag.length - 2] !== "}") {
            isValid = false;
            currentTagValid = false;
          }
        } else {
          isValid = false;
          currentTagValid = false;
        }
      }
      if (isValid) {
        for (let i = 0; i < tag.length; i++) {
          if (
            i === 0 ||
            (isCustom && i === 1) ||
            i === tag.length - 1 ||
            (isCustom && i === tag.length - 2)
          ) {
            continue;
          }
          if (tag[i] === "{" || tag[i] === "}") {
            isValid = false;
            currentTagValid = false;
          }
        }
      }
      if (currentTagValid === true) {
        validTags += 1;
      }
    });
  }
  if (isCustom && validTags > 0) {
    return true;
  }
  return isValid;
};

export const isValidJson = (jsonString) => {
  try {
    JSON.parse(jsonString);
    return true;
  } catch (error) {
    return false;
  }
};

export const isValidJsonString = (jsonString) => {
  if (jsonString && jsonString[0] !== "{") {
    return false;
  }
  try {
    JSON.parse(jsonString);
  } catch (e) {
    return false;
  }
  return true;
};

export const isValidUrl = (urlString) => {
  try {
    new URL(urlString);
  } catch (e) {
    console.error(e);
    return false;
  }
  return true;
};

export const scrollToStepById = (stepPath, block = "center") => {
  const stepElementId = `expended_step_${stepPath.join("")}`;
  const element = document.getElementById(stepElementId);

  element &&
    !isElementInView(element) &&
    element.scrollIntoView({
      behavior: "smooth",
      block: block,
      inline: "nearest",
    });
};

export const autoExpandStep = ({ stepPath, stepSection }, setFieldValue) => {
  if (stepPath) {
    const stepFieldPath = `steps[${stepPath.join("].steps[")}]`;
    setFieldValue(`${stepFieldPath}.isCollapsed`, false);
    if (stepSection)
      setFieldValue(
        `${stepFieldPath}.${
          stepSection === "CONFIGURATION"
            ? "isStepSectionCollapsed"
            : "isAccountSectionCollapsed"
        }`,
        false
      );
    setTimeout(() => scrollToStepById(stepPath), 2);
  }
};

export const isElementInView = (element) => {
  const elementPos = element.getBoundingClientRect();
  const isElementInView =
    elementPos.top >= 0 &&
    elementPos.left >= 0 &&
    elementPos.bottom <= window.innerHeight &&
    elementPos.right <= window.innerWidth;
  return isElementInView;
};

function isNumericString(str) {
  if (typeof str !== "string") return false; // we only process strings!
  return (
    !isNaN(str) && !isNaN(parseFloat(str)) // use type coercion to parse the _entirety_ of the string (`parseFloat` alone does not do this)...
  ); // ...and ensure strings of whitespace fail
}

export const isValidCustomFieldOptions = (options) => {
  // checks if options is valid array for custom fields mapping.
  if (Array.isArray(options)) {
    return options.every(
      (obj) =>
        obj.hasOwnProperty("id") &&
        obj.hasOwnProperty("title") &&
        obj.hasOwnProperty("type")
    );
  }
  return false;
};

export const removeTagBraces = (tag) => {
  if (typeof tag === "string") {
    return tag.replace("{", "").replace("}", "");
  }
  return tag;
};

export const refreshLockTimeOut = (flowId, refreshNow) => {
  const { pathname } = window.location;
  if (pathname.includes(`${flowId}/edit/`) || !refreshNow) {
    if (refreshNow) {
      const { refreshLock } = useTemplateBuilderAPI();
      refreshLock(flowId);
    }
    setTimeout(function () {
      refreshLockTimeOut(flowId, true);
    }, 20000); // lock refreshes every 60 seconds
  }
};

export const generateUserName = (userObject) => {
  const { username, first_name, last_name } = userObject;
  let userName = username || "";
  if (first_name) userName = first_name;
  if (last_name) userName = `${userName} ${last_name}`;
  return userName;
};

export const generateUserInitials = (userObject) => {
  if (userObject) {
    const { username = "", first_name = "", last_name = "" } = userObject || {};
    let userName = username.toUpperCase()[0];
    if (first_name) userName = first_name.toUpperCase()[0];
    if (last_name) userName = `${userName}${last_name.toUpperCase()[0]}`;
    return userName;
  }
  return "";
};

export const generateToast = (title, message) => {
  toast(
    <div>
      <div className="lable" style={{ marginTop: 0 }}>
        {title}
      </div>
      <div className="message" style={{ width: "auto", marginRight: "10px" }}>
        {message}
      </div>
    </div>
  );
};

export const lockedFlowToolTipText = (locked_by, flowName) => {
  return (
    <div>
      ‘{flowName}’ is currently locked as it is being edited by{" "}
      <div className="tooltip-username">
        {generateUserName(locked_by?.user)}
      </div>
      . You’ll have to wait until no other member is working on this module.
    </div>
  );
};

export const navigatingToLockedCard = (lockFlow, user, locked_by, id) => {
  const lockedFlow = locked_by && locked_by?.user?.id !== user?.id && lockFlow;
  if (lockedFlow) {
    const shouldScrollToCard = window.location.search?.includes(
      `?locked_flow=${id}`
    );
    if (shouldScrollToCard) {
      const cardElementId = `locked_flow_card_${id}`;
      const element = document.getElementById(cardElementId);

      if (element && !isElementInView(element)) {
        element.scrollIntoView({
          behavior: "smooth",
          block: "center",
          inline: "nearest",
        });
        return true;
      }
    }
  }
};

export const invalidMappingField = (templateStep) => {
  const templateFieldsWithIndex = templateStep.fields.map((field, index) => {
    // append field indexes against all fields
    return { ...field, fieldIndex: index };
  });

  const invalidMappingFields = templateFieldsWithIndex.filter(
    // all mapping fields should either be visible or should have a mapping_source
    (field) =>
      field.activity_field_type === "FIELD_MAPPING" &&
      (!field.is_visible || !templateStep.is_visible) &&
      !field.default_value
  );

  if (invalidMappingFields.length) return invalidMappingFields[0];
};

export const autoEnableErrorNotifications = (
  formikRef,
  accountNotificationSettings,
  scenario
) => {
  const { is_error_notification_enabled: errorNotificationEnabled } =
    formikRef?.current?.meta || {};

  if (
    accountNotificationSettings?.is_error_notification_enabled &&
    scenario === "CREATE" &&
    !errorNotificationEnabled
  )
    formikRef?.current?.setFieldValue(
      "meta.is_error_notification_enabled",
      true
    );
};

export const allowStepExpansion = (activityType) => {
  let isExpandible = true;
  if (activityType === "SETUP" && isIntegrationBuilder()) isExpandible = false;
  return isExpandible;
};

const getLoopOutTags = (loopStep, steps) => {
  let outTags = {};
  try {
    if (
      loopStep?.activity_type === "LOOP" ||
      loopStep?.activity?.type === "LOOP"
    ) {
      const variableName = loopStep?.fields?.find(
        (field) =>
          field.activity_field_machine_name === "current_item_variable_name" ||
          field.machine_name === "current_item_variable_name"
      )?.default_value;
      const inputArraySource = loopStep?.fields?.find(
        (field) =>
          field.activity_field_machine_name === "input_array" ||
          field.machine_name === "input_array"
      ).default_value;
      if (variableName) {
        outTags = {
          [variableName]: {},
        };
      }
      const stepMachineName = getmachineNameFromTag(inputArraySource);
      if (stepMachineName) {
        const sourceStep = getNodeByMachineName(steps, stepMachineName);
        if (sourceStep) {
          let sourceActivityOutput = decodeJSON(
            sourceStep?.activity?.activity_output
          ); // inputArraySource will always be a array.
          const nestedSourcePath = getObjectPathFromTag(inputArraySource);
          if (nestedSourcePath) {
            // if tag is for nested object,, like {steps.mailchimp_get_all_subscribers.out.members}, we need to resolve the object path
            sourceActivityOutput = resolveObjectPath(
              sourceActivityOutput,
              nestedSourcePath
            );
          }
          if (
            Array.isArray(sourceActivityOutput) &&
            sourceActivityOutput.length &&
            variableName
          ) {
            outTags = {
              [variableName]: sourceActivityOutput[0],
            };
          }
        }
      }
    }
  } catch (error) {
    console.error("Error in parsing activity output", error);
  }
  return outTags;
};

const getOuputFromActivityField = ({ activityFields, machineName }) => {
  let output = {};
  try {
    const activityField = activityFields.find(
      (field) => field.machine_name === machineName
    );
    if (activityField) {
      output = decodeJSON(activityField.default_value);
    }
  } catch (error) {
    console.error("Error in parsing activity output", error);
  }
  return output;
};

export const getTagsForTemplateStep = ({
  selectedTmplateStep,
  steps,
  includeOutJson = false,
  includeOnly = "",
}) => {
  const {
    await_get_value,
    append_value,
    increment_value,
    decrement_value,
    set_value,
    delete_key,
    get_value,
  } = config.storage;
  const storageActivityIds = [
    await_get_value.id,
    append_value.id,
    increment_value.id,
    decrement_value.id,
    set_value.id,
    delete_key.id,
    get_value.id,
  ];

  if (selectedTmplateStep) {
    if (selectedTmplateStep?.activity?.type === "CODE") {
      return getCodeStepTags(selectedTmplateStep);
    }
    if (
      selectedTmplateStep?.activity?.type === "CONDITION_IF" ||
      selectedTmplateStep?.activity?.type === "CONDITION_ELSEIF"
    ) {
      return getConditionStepTags(selectedTmplateStep);
    }

    // testResult = JSON.parse(selectedTmplateStep.test_output);
    let testResult = {};
    let outTags = {};
    let allTags = {};
    let sdkTags = {};
    let objTags = {};
    let inTags = (sdkTags = objTags = {});
    // let objJson = {};
    let outJson = {};
    const { activity } = selectedTmplateStep;
    const tagFromActivity = getAvailableTagsFromActivity(
      selectedTmplateStep.activity,
      selectedTmplateStep
    );
    if (selectedTmplateStep.test_output) {
      if (selectedTmplateStep.test_output?.out) {
        try {
          testResult = {
            ...testResult,
            out: JSON.parse(selectedTmplateStep.test_output?.out),
          };
        } catch (e) {
          console.log(e);
          testResult = {
            ...testResult,
            out: null,
          };
        }
      }
      if (selectedTmplateStep.test_output?.in) {
        try {
          testResult = {
            ...testResult,
            in: JSON.parse(selectedTmplateStep.test_output?.in),
          };
        } catch (e) {
          console.log(e);
          testResult = {
            ...testResult,
            in: null,
          };
        }
      }
    }

    /* The 'in' and 'sdk' tags should be forwarded as an object and not as array, this would only run if the tested step had no data for IN or SDK */
    !Object.keys(inTags).length &&
      tagFromActivity.inTags &&
      tagFromActivity.inTags.length &&
      tagFromActivity.inTags.forEach(
        (tag) => (inTags = { ...inTags, [tag]: "" })
      );
    !Object.keys(sdkTags).length &&
      tagFromActivity.sdkTags &&
      tagFromActivity.sdkTags.length &&
      tagFromActivity.sdkTags.forEach(
        (tag) =>
          (sdkTags = {
            ...sdkTags,
            [tag[0] === "s" ? tag.substring(4) : tag]: "",
          })
      );

    if (activity && activity.activity_output) {
      try {
        outTags =
          testResult?.out || JSON.parse(activity && activity.activity_output);
      } catch (error) {
        console.error("Error in parsing activity output");
      }
    }

    if (storageActivityIds.includes(selectedTmplateStep?.activity_id)) {
      const includeOutTag = [await_get_value.id, get_value.id].includes(
        selectedTmplateStep?.activity_id
      );
      if (includeOutTag) {
        return {
          success: true,
          out: outTags,
        };
      }
      return {
        success: true,
      };
    }

    try {
      if (
        selectedTmplateStep?.activity_type === "LOOP" ||
        selectedTmplateStep?.activity?.type === "LOOP"
      ) {
        const variableName = selectedTmplateStep?.fields?.find(
          (field) =>
            field.activity_field_machine_name ===
              "current_item_variable_name" ||
            field.machine_name === "current_item_variable_name"
        )?.default_value;
        const inputArraySource = selectedTmplateStep?.fields?.find(
          (field) =>
            field.activity_field_machine_name === "input_array" ||
            field.machine_name === "input_array"
        ).default_value;
        if (variableName) {
          outTags = {
            [variableName]: {},
          };
        }
        const stepMachineName = getmachineNameFromTag(inputArraySource);
        if (stepMachineName) {
          const sourceStep = getNodeByMachineName(steps, stepMachineName);
          if (sourceStep) {
            let sourceActivityOutput = {};
            if (sourceStep.activity_id === config.makeHttpCall.activityId) {
              sourceActivityOutput =
                getOuputFromActivityField({
                  activityFields: sourceStep.fields,
                  machineName: "output",
                })?.out || "";
            } else if (
              sourceStep.activity_id === config.incomingWebhook.activityId
            ) {
              const payload = decodeJSON(
                sourceStep.fields[6].default_value // selected object in incoming webhook step
              );
              sourceActivityOutput = testResult?.out || payload || {};
            } else if (sourceStep?.activity?.type === "CODE") {
              const codeStepOutput =
                getOuputFromActivityField({
                  activityFields: sourceStep.fields,
                  machineName: "output",
                })?.result?.parsedResponseTemplate || "";
              sourceActivityOutput = decodeJSON(codeStepOutput);
            } else {
              sourceActivityOutput = decodeJSON(
                sourceStep?.activity?.activity_output
              ); // inputArraySource will always be a array.
            }
            const nestedSourcePath = getObjectPathFromTag(inputArraySource);
            if (nestedSourcePath && sourceActivityOutput) {
              // if tag is for nested object,, like {steps.mailchimp_get_all_subscribers.out.members}, we need to resolve the object path
              sourceActivityOutput = resolveObjectPath(
                sourceActivityOutput,
                nestedSourcePath
              );
            }
            if (
              Array.isArray(sourceActivityOutput) &&
              sourceActivityOutput.length &&
              variableName
            ) {
              outTags = {
                [variableName]: sourceActivityOutput[0],
              };
            }
          }
        }
      }
    } catch (error) {
      console.error("Error in parsing activity output", error);
    }

    allTags = {
      Obj: objTags,
      Out: outTags,
      In: inTags,
      Sdk: sdkTags,
      network_code: "",
    };

    if (selectedTmplateStep.activity_id === config.incomingWebhook.activityId) {
      if (!outTags || !Object.keys(outTags || {}).length) {
        // If no data in out tags, we need to parse the selected object from the incoming webhook step
        try {
          const payload = decodeJSON(
            selectedTmplateStep.fields[6].default_value // selected object in incoming webhook step
          );
          if (testResult?.out && Object.keys(testResult?.out).length) {
            outTags = testResult?.out;
          } else if (payload.payload) {
            outTags = decodeJSON(payload.payload) || {};
          } else if (payload) {
            outTags = payload || {};
          }
        } catch (error) {
          console.error("Error in parsing activity output", error);
        }
      }
      allTags = {
        Out: outTags,
        meta: {
          webhook_receive_url:
            testResult?.stepResultsState?.resultData?.parsedUrl || "",
        },
      };
    }

    if (selectedTmplateStep.activity_id === config.external_action.activityId) {
      // If no data in out tags, we need to parse the selected object from the external step fields
      try {
        outTags =
          testResult?.out ||
          decodeJSON(
            selectedTmplateStep.fields[5].default_value // outout of the external step
          ) ||
          {};
      } catch (error) {
        console.error("Error in parsing activity output", error);
      }
      allTags = {
        Out: outTags,
        network_code: "",
      };
    }

    if (selectedTmplateStep.activity_id === config.makeHttpCall.activityId) {
      // If no data in out tags, we need to parse the selected object from the external step fields
      try {
        outTags =
          decodeJSON(selectedTmplateStep.fields[5].default_value)?.out || {};
      } catch (error) {
        console.error("Error in parsing activity output", error);
      }
      allTags = {
        Out: outTags,
        network_code: "",
      };
    }

    // If step's visibility is turned off no SDK tags should be visible
    !selectedTmplateStep.is_visible && delete allTags.Sdk;

    // If no data in obj we dont need to show its tag
    if (!allTags.Obj) {
      delete allTags.Obj;
    } else if (typeof allTags.Obj === "object") {
      !Object.keys(allTags.Obj).length && delete allTags.Obj;
    }

    if (includeOutJson) {
      allTags = { ...allTags, out_json: encodeJSON(allTags.Out) };
    }

    if (selectedTmplateStep.is_form_step) {
      // get values from testResult?.in  output for each  allTags.In fields, if available
      const formStepTags = {};
      Object.keys(allTags.In).forEach((tag) => {
        formStepTags[tag] = testResult?.in?.[tag] || ""; // read the value from testResult?.in
      });

      allTags = {
        In: formStepTags,
      };
    }
    if (
      activity?.type === ActivityType.Loop ||
      selectedTmplateStep?.activity_type === ActivityType.Loop
    ) {
      allTags = { Out: allTags.Out };
      if (allTags.Out.item) {
        allTags = { Item: allTags.Out.item };
      }
    }

    switch (includeOnly) {
      case "In":
        return {
          In: inTags,
        };
      case "meta":
        return {
          meta: allTags.meta,
        };
      default:
        return allTags; /* Some steps like schedule steps support limited tag types, we can filter other types here */
    }
  }
  return null;
};

export const getTemplateTags = ({
  tree,
  childrenName,
  path = [],
  isRoot = true,
  includeAllTags = false,
  includeOutJson = false,
  templateSteps = [],
}) => {
  let response = {};
  if (isRoot) {
    tree[childrenName].forEach((child, index) => {
      const currentPath = [...path, index];
      const subResponse = getTemplateTags({
        tree: { ...child, path: currentPath },
        childrenName,
        path: currentPath,
        isRoot: false,
        includeAllTags,
        includeOutJson,
        templateSteps: tree, // pass the complete tree to getTagsForTemplateStep}
      });
      response = { ...response, ...subResponse };
    });
  } else {
    const treeCopy = Object.assign({}, tree);
    delete treeCopy[childrenName];
    const stepTags = getTagsForTemplateStep({
      steps: templateSteps,
      selectedTmplateStep: treeCopy,
      includeOutJson,
    });
    if (includeAllTags) {
      response = {
        ...response,
        [treeCopy.machine_name]: {
          item: stepTags.Item,
          out: stepTags.Out || stepTags.out,
          in: stepTags.In,
          sdk: stepTags.Sdk,
          obj: stepTags.Obj,
          out_json: stepTags.out_json,
        },
      };
    } else {
      response = {
        ...response,
        [treeCopy.machine_name]: {
          out: stepTags?.Out || {},
        },
      };
    }

    tree[childrenName]?.forEach((child, index) => {
      const currentPath = [...path, index];
      const subResponse = getTemplateTags({
        tree: { ...child, path: currentPath },
        childrenName,
        path: currentPath,
        isRoot: false,
        includeAllTags,
        includeOutJson,
        templateSteps,
      });
      response = { ...response, ...subResponse };
    });
  }
  return response;
};

export const getFlowBlocksForTagMenu = ({
  steps,
  currentStepPath = [],
  isFormPage = false,
}) => {
  // recursively map the steps
  const mapSteps = (steps, path = []) => {
    return steps.map((step, index) => {
      const stepPath = [...path, index];
      const isStepBelongsToCurrentBlock =
        currentStepPath.length > 0 && currentStepPath[0] === stepPath[0];
      const isTriggerStep =
        step.activity_id === config.incomingWebhook.activityId;
      const isSetupFormBlock = stepPath[0] === 0;
      if (
        !isStepBelongsToCurrentBlock &&
        !isTriggerStep &&
        !isSetupFormBlock &&
        !isFormPage // if current step is not form page, then all steps will be enabled
      ) {
        return {
          ...step,
          isDisabled: true,
          steps: mapSteps(step.steps, stepPath),
        };
      }
      if (!isStepBelongsToCurrentBlock && isTriggerStep) {
        return {
          ...step,
          includeOnly: "meta",
          steps: mapSteps(step.steps, stepPath),
        };
      }
      return {
        ...step,
        steps: mapSteps(step.steps, stepPath),
      };
    });
  };
  let clonedSteps = mapSteps(cloneDeep(steps));

  if (currentStepPath.length > 0) {
    const currentStep = getNode(clonedSteps, "steps", currentStepPath);
    let newTree = { steps: clonedSteps };
    if (
      currentStep &&
      currentStep.activity_type !== config.doWhileLoop.dummyStepType
    ) {
      newTree = removeNode({ steps: clonedSteps }, "steps", currentStepPath); // remove the current step from the tree
    }
    clonedSteps = newTree.steps;
  }
  const [setupFrom, ...blocks] = clonedSteps || [];
  const blocksSteps = blocks.map((block) => {
    return {
      ...block,
      expandOnSelect: true,
      hideIcon: true,
      comments: `${block.comments} (Block)`,
      machine_name: `${block.machine_name}_block`,
      steps: [{ ...block, steps: [] }, ...block.steps],
    };
  });
  const visibleFormSteps = setupFrom.steps
    .filter(
      (step) =>
        step.step_type === "CUSTOMER_FORM" ||
        step.fields?.filter((f) => f.is_visible).length > 0
    )
    .map((step) => {
      return {
        ...step,
        fields: step.fields.filter((f) => f.is_visible),
      };
    });

  const flowBlocks = [
    ...(visibleFormSteps.length > 0
      ? [
          {
            steps: visibleFormSteps,
            comments: "Setup Form",
            activity_type: "SETUP_FORM",
            isCustom: true,
            hideIcon: true,
            expandOnSelect: true,
          },
        ]
      : []),
    ...(blocksSteps.length > 0
      ? [
          {
            steps: blocksSteps,
            comments: "Triggers",
            activity_type: "BLOCKS",
            isCustom: true,
            hideIcon: true,
            expandOnSelect: true,
          },
        ]
      : []),
  ];
  return flowBlocks;
};

export const getAllFormPageMachineNames = (tree, isRoot = false) => {
  const response = [];
  if (isRoot) {
    tree.steps.forEach((child) => {
      const subResponse = getAllFormPageMachineNames(child);
      response.push(...subResponse);
    });
  } else {
    response.push(tree.form_step_machine_name);
    if (Array.isArray(tree.steps))
      tree.steps.forEach((child) => {
        const subResponse = getAllFormPageMachineNames(child);
        response.push(...subResponse);
      });
  }
  return response;
};

export const getFormPagesOfSteps = (template, step) => {
  // get all form pages from the steps and its children
  const formPagesMachineNames = getAllFormPageMachineNames(step);
  const setupform = template?.steps?.[0]?.steps || [];
  if (Array.isArray(setupform) && setupform.length > 0) {
    const formPages = setupform.filter((step) =>
      formPagesMachineNames.includes(step.machine_name)
    );
    return formPages;
  }
  return [];
};

const getIncomingWebhookPayloadTags = (step) => {
  if (step.activity_id !== config.incomingWebhook.activityId) {
    return {};
  }

  try {
    const payload = decodeJSON(step.fields[6].default_value);
    return decodeJSON(payload.payload) || {};
  } catch (error) {
    console.error("Error in parsing webhook payload:", error);
    return {};
  }
};

export const getSourceStepTags = (steps, machine_name) => {
  const sourceStep = getNodeByMachineName(steps, machine_name);
  const output = getTagsForTemplateStep({
    selectedTmplateStep: sourceStep,
    steps,
  }) || { Out: {} };
  const outputKey = sourceStep?.activity?.type === "LOOP" ? "item" : "out";
  return {
    steps: {
      [machine_name]: { [outputKey]: { ...output.Out } },
    },
  };

  /* return if (sourceStep) {
    if (
      sourceStep.activity_type === "LOOP" ||
      sourceStep.activity?.type === "LOOP"
    ) {
      return { steps: { [machine_name]: getLoopOutTags(sourceStep, steps) } };
    }
    return sourceStep.activity_id === config.incomingWebhook.activityId
      ? getIncomingWebhookPayloadTags(sourceStep)
      : decodeJSON(sourceStep?.activity?.activity_output);
  }
  return {}; */
};

export const replaceValuesWithJSON = (inputString, jsonData) => {
  // Regular expression to match values inside double curly braces starting with 'steps.' or 'storage.'
  const regex = /{(steps|storage)\.(.*?)}/g;

  // Use the replace method to replace matched values
  const replacedString = inputString.replace(regex, (match, prefix, group) => {
    // Trim the group to remove leading and trailing whitespaces
    let key = prefix + "." + group.trim();

    // Convert array access to dot notation: `a[0].b` to `a.0.b`
    key = key.replace(/\[(\d+)\]/g, ".$1");

    // Access the nested values using a loop
    const keys = key.split(".");
    let value = jsonData;

    // eslint-disable-next-line no-restricted-syntax
    for (const k of keys) {
      // eslint-disable-next-line no-prototype-builtins
      if (value.hasOwnProperty(k)) {
        value = value[k];
      } else {
        // If a key is not found, return an empty string
        if (key.includes("authorization.")) {
          // skip auth tags
          return `{${key}}`;
        }
        return ""; // If a key is not found, return the key as is
      }
    }

    // Return the replacement value
    if (typeof value !== "string") {
      value = JSON.stringify(value);
    }
    return value;
  });

  return replacedString;
};

export const extractPathFromFeildName = (input) => {
  // input format: "steps[1].steps[0].fields.6.default_value"
  // Regular expression to find numbers after 'steps' and within square brackets
  const regex = /steps\[(\d+)\]/g;
  let matches;
  const numbers = [];

  // Loop over all matches and push the captured numbers into the array
  while ((matches = regex.exec(input)) !== null) {
    // matches[1] contains the captured group which is the number after 'steps['
    numbers.push(parseInt(matches[1], 10));
  }

  return numbers;
};

export const getPreviousStepPath = (path) => {
  const lastIndex = path.length - 1;
  const previousStepPath = [...path];
  previousStepPath[lastIndex] = previousStepPath[lastIndex] - 1;
  if (previousStepPath[lastIndex] < 0) {
    previousStepPath.splice(-1, 1);
  }
  return previousStepPath;
};

export const getNextStepPath = (path) => {
  if (!Array.isArray(path) || path.length === 0) return [];
  const lastIndex = path.length - 1;
  const nextStepPath = [...path];
  nextStepPath[lastIndex] = nextStepPath[lastIndex] + 1;
  return nextStepPath;
};

export const getMachineNameMap = (tree, isRoot = false) => {
  let machineNameMap = {};
  if (isRoot) {
    tree.steps.forEach((child) => {
      const subResponse = getMachineNameMap(child);
      machineNameMap = { ...machineNameMap, ...subResponse };
    });
  } else {
    machineNameMap[tree.old_machine_name] = tree.machine_name;
    if (Array.isArray(tree.steps))
      tree.steps.forEach((child) => {
        const subResponse = getMachineNameMap(child);
        machineNameMap = { ...machineNameMap, ...subResponse };
      });
  }
  return machineNameMap;
};

export const updateSepsFieldUsingMap = ({
  step,
  fieldName,
  valueMap,
  istRoot = false,
}) => {
  // Step: Template step object
  // fieldName: Field name to be updated eg, 'machine_name'
  // valueMap: Map of old value to new value eg, { old_machine_name: machine_name }
  const clonedStep = cloneDeep(step);

  if (istRoot) {
    clonedStep.steps = clonedStep.steps.forEach((child) => {
      updateSepsFieldUsingMap({ step: child, fieldName, valueMap });
    });
  }

  if (clonedStep[fieldName] && valueMap[clonedStep[fieldName]]) {
    clonedStep[fieldName] = valueMap[clonedStep[fieldName]];
  }

  if (clonedStep.steps && clonedStep.steps.length > 0) {
    clonedStep.steps = clonedStep.steps.map((child) =>
      updateSepsFieldUsingMap({ step: child, fieldName, valueMap })
    );
  }
  return clonedStep;
};
