/* eslint-disable import/prefer-default-export */
import { Field } from "features/templates/template-builder-v7/services/template-builder-interface";

export const getStepFiledDefaultValue = (
  fields: Field[],
  fieldMachineName: string
): string => {
  const field = fields.find(
    (f) =>
      (f.activity_field_machine_name || f.machine_name) === fieldMachineName
  );
  return field?.default_value || "";
};
