/* eslint-disable @typescript-eslint/no-explicit-any */
import useHashBasedAuth from "hooks/useHashBasedAuth";
import { useFetchWorkspaceVariableQuery } from "features/account-settings/variables/api/useWorkspaceVariables";
import { useFetchAppUserVariableQuery } from "features/account-settings/variables/api/useUserVariables";
import { useFetchUserQuery } from "utils/user/user-api";

import {
  transfromAppUserVariablesToTags,
  transfromWorkspaceVariablesToTags,
} from "features/templates/utils/steps-tags";

const useVariableTags = (): {
  workspaceVariableTags: Record<string, any>;
  userVariablesTags: Record<string, any>;
} => {
  const { getAuthParams } = useHashBasedAuth();
  const { data: user } = useFetchUserQuery();
  const userId = `INTEGRY_TEST_INTEGRATION_CREATOR_${user?.account_id}`;
  const authParams = getAuthParams({
    userId,
  });

  const { data: workspaceVariables } = useFetchWorkspaceVariableQuery(
    undefined,
    {
      skip: !user?.id,
      refetchOnFocus: true,
    }
  );
  const { data: appUserVariables } = useFetchAppUserVariableQuery(
    {
      queryParams: authParams,
      includeEmptyRow: false,
    },
    {
      skip: !user?.id,
      refetchOnFocus: true,
    }
  );

  let workspaceVariableTags = {};
  let userVariablesTags = {};
  if (workspaceVariables?.results) {
    workspaceVariableTags =
      transfromWorkspaceVariablesToTags(workspaceVariables?.results) || [];
  }
  if (appUserVariables?.results) {
    const userVariables = [
      {
        id: 0,
        key: "userId",
        value: user?.email || user?.username,
        is_required: true,
        is_system_generated: true,
      },
      ...appUserVariables?.results,
    ];
    userVariablesTags = transfromAppUserVariablesToTags(userVariables) || [];
  }
  return { workspaceVariableTags, userVariablesTags };
};

export default useVariableTags;
