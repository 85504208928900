/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/interactive-supports-focus */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { ReactElement, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import LoadingIcon from "components/integry-design-system/molecules/button/LoadingIcon";
import OverflowTooltip from "components/common/overflow-tooltip";
import { User } from "types/integry-user";
import urls, { externalUrl } from "utils/router-urls";
import config from "utils/config";
import { useChangeWorkspaceMutation } from "utils/user/user-api";
import "./navbar-dropdown-menu.scss";

interface Iprops {
  closeMenu?: () => void;
  icons: string[];
  user: User;
}

const NavbarDropdownMenu = ({
  closeMenu,
  icons,
  user,
}: Iprops): ReactElement => {
  const {
    email,
    first_name,
    last_name,
    account_name,
    accounts = [],
    account,
  } = user;
  const [showSwitchWorkspace, setShowSwitchWorkspace] = useState(false);
  const [nextWorkspace, setNextWorkspace] = useState<number>();
  const name = `${first_name}${last_name ? ` ${last_name}` : ""}`;

  const [changeWorkspace, { isSuccess }] = useChangeWorkspaceMutation();

  useEffect(() => {
    if (isSuccess) {
      window.open(`/wapp${urls.homePage}`, "_self");
    }
  }, [isSuccess]);

  return (
    <div
      className="navbar-dropdown-menu-container"
      onClick={() => closeMenu && closeMenu()}
      role="menu"
    >
      {showSwitchWorkspace ? (
        <>
          <div
            className="navbar-dropdown-menu-item workspace-list-header"
            onClick={(e) => {
              setShowSwitchWorkspace(false);
              e.preventDefault();
              e.stopPropagation();
            }}
          >
            <img src={icons[7]} alt="back-icon" height={24} width={24} />
            Switch workspace
          </div>
          <div className="workspace-list-container integry-scrollbar-v2">
            {accounts.map((workspace) => (
              <div
                className="navbar-dropdown-menu-item hide-separator flex-space-between"
                onClick={(e) => {
                  if (workspace.is_currently_selected) {
                    setShowSwitchWorkspace(false);
                    return;
                  }
                  changeWorkspace({ accountId: workspace.id });
                  setNextWorkspace(workspace.id);
                  localStorage.setItem(
                    "currentWorkspaceId",
                    JSON.stringify(workspace.id)
                  );
                  e.preventDefault();
                  e.stopPropagation();
                }}
                key={workspace.id}
              >
                <div className="flex gap-8px">
                  <img
                    src={workspace.icon_url}
                    alt="workspace-icon"
                    className="workspace-icon"
                  />
                  <div className="workspace-name overflow-elipsis">
                    {workspace.name}
                  </div>
                </div>
                {workspace.is_currently_selected && (
                  <img src={icons[6]} alt="arrow-icon" height={9} width={12} />
                )}
                {workspace.id === nextWorkspace && (
                  <LoadingIcon color="#4250F0" />
                )}
              </div>
            ))}
          </div>
        </>
      ) : (
        <>
          <div
            className="navbar-dropdown-user-info"
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
            }}
          >
            <div className="flex gap-8px">
              <img
                src={account?.icon_url}
                alt="workspace-icon"
                className="current-account-icon"
              />
              <div className="account-name overflow-elipsis align-center">
                {account_name}
              </div>
            </div>

            <OverflowTooltip className="full-name" text={name} />
            <OverflowTooltip className="email" text={email} />
          </div>

          <Link to={urls.companyProfile} className="navbar-dropdown-menu-item">
            <img src={icons[0]} alt="geat-icon" />
            Workspace Settings
          </Link>

          {accounts.length > 1 && (
            <div
              className="navbar-dropdown-menu-item"
              onClick={(e) => {
                setShowSwitchWorkspace(true);
                e.preventDefault();
                e.stopPropagation();
              }}
            >
              <img src={icons[8]} alt="geat-icon" className="workspace-icon" />
              Switch workspace
              <img src={icons[5]} alt="arrow-icon" className="arrow-icon" />
            </div>
          )}

          <a
            href={externalUrl.privacyPolicy}
            className="navbar-dropdown-menu-item"
            target="_blank"
            rel="no noreferrer"
          >
            <img src={icons[2]} alt="privacy-icon" />
            Privacy Policy
            <img
              src={icons[4]}
              alt="open-link-icon"
              className="open-link-icon"
            />
          </a>
          <a href={config.getLogoutUrl()} className="navbar-dropdown-menu-item">
            <img src={icons[3]} alt="logout-icon" />
            Log out
          </a>
        </>
      )}
    </div>
  );
};

export default NavbarDropdownMenu;
